<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="is_enable" slot-scope="text, record">
          <a-switch @change="handleUsed(record)" :checked="text"/>
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleRelatedCategory(record)" v-if="record.category === 2">关联分类</a>
            <a @click="handleRelatedGoods(record)" v-if="record.category === 3">关联商品</a>
            <a-divider type="vertical" v-if="record.category === 3 || record.category === 2"/>
            <a @click="handleRelatedUser(record)">领取用户</a>
          </template>
        </span>
      </s-table>
    </a-card>
    <create-coupon-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { createCoupon, putCoupon, couponList } from '@/api/applet_shop_coupon'
import CreateCouponForm from '@/views/a-applet/shop_manage/coupon/module/CreateCouponForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateCouponForm
  },
  data () {
    return {
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          ellipsis: true,
          dataIndex: 'name'
        },
        {
          title: '优惠类型',
          ellipsis: true,
          dataIndex: 'category',
          customRender: text => this.$Dictionaries.coupon_category[text] || '无'
        },
        {
          title: '满减',
          ellipsis: true,
          dataIndex: 'value',
          customRender: (text, record, index) => '满' + (record.limit / 100).toFixed(2) + '减' + (record.value / 100).toFixed(2)
        },
        {
          title: '总量',
          ellipsis: true,
          dataIndex: 'max_count'
        },
        {
          title: '单人限量',
          ellipsis: true,
          dataIndex: 'max_user_count'
        },
        {
          title: '新用户专用',
          ellipsis: true,
          dataIndex: 'is_new',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '前台显示',
          ellipsis: true,
          dataIndex: 'is_show',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '启用',
          ellipsis: true,
          dataIndex: 'is_enable',
          scopedSlots: { customRender: 'is_enable' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return couponList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleCreate () {
      // this.mdl = null
      // this.visible = true
      // this.confirmLoading = false
      this.$router.push({ name: 'CouponCreate' })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          if (this.mdl && this.mdl.id) {
            putCoupon(values, this.mdl.id).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            }).finally(() => {
              this.confirmLoading = false
            })
          } else {
            console.log('提交的数据----', values)
            values.limit = Math.round(values.limit * 100)
            values.value = Math.round(values.value * 100)
            createCoupon(values).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleUsed (item) {
      console.log(item)
      this.loading = true
      putCoupon({ is_enable: !item.is_enable }, item.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleRelatedCategory (item) {
      console.log(item)
      this.$router.push({ path: '/commonshop/coupon_category', query: { id: item.id } })
    },
    handleRelatedGoods (item) {
      console.log(item)
      this.$router.push({ path: '/commonshop/coupon_goods', query: { id: item.id } })
    },
    handleRelatedUser (item) {
      console.log(item)
      this.$router.push({ path: '/commonshop/coupon_user', query: { id: item.id } })
    }
  }
}
</script>
